import {
  CustomerServiceIcon,
  InsuranceIcon,
  LiveTrackingIcon,
  RatesIcon
} from './asset-constants';

const packagesArray = [
  {
    title: 'China Imports',
    amount: '15',
    currency: 'USD',
    tags: [
      'Ship from suppliers, manufacturers in China',
      'Inclusive of customs clearance charges',
      'Delivery in 6 - 10 days',
      'No pickups',
      'No doorstep delivery'
    ]
  },

  {
    title: 'Shop and Ship',
    amount: '20',
    currency: 'USD',

    tags: [
      'Shop from international stores and deliver to an address in Canada, the UK or USA',
      'Doorstep delivery (FREE within Lagos,extra charges if outside Lagos)',
      'Inclusive of customs clearance charges',
      'Delivery in 7 - 14 working days'
    ],
    isRecommended: true,
    additionalNote:
      'Packages are shipped out once a week (Fridays only). Delivery timeline begins the following week after your package has been delivered to our Canada, UK or USA partner Drop-off centers'
  },
  {
    title: 'Express Imports',
    amount: '69,093',
    currency: 'NGN',
    tags: [
      'Import from over 200+ cities',
      'Doorstep delivery',
      'Delivery in 3 - 7 working days after pick-up',
      'Not inclusive of customs clearance charges'
    ]
  }
];

const deliveryBenefitsArray = [
  {
    icon: CustomerServiceIcon,
    content: 'Reliable  and friendly customer support representatives'
  },
  {
    icon: RatesIcon,
    content: 'Affordable Rates'
  },
  {
    icon: LiveTrackingIcon,
    content: 'Live-tracking and notifications on shipments'
  },
  {
    icon: InsuranceIcon,
    content: 'Flexible insurance options'
  }
];
export { deliveryBenefitsArray, packagesArray };
