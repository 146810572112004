import Slider from 'react-slick';
import {
  MobileOptionsIcon,
  OptionsIcon,
  SlideMain,
  SlideMainMobile,
  SlideSeaFreight,
  SlideSeaFreightMobile
  // FreeShippingCampaign,
  // FreeShippingCampaignMobile
} from './asset-constants';
import { OurCountriesImgMobile, QrCode } from '../../constants/asset-constants';

const imageList: {
  image: string;
  uri: string;
  mobile: string;
  uris?: string[];
}[] = [
  // {
  //   image: SlideMain,
  //   mobile: SlideMainMobile,
  //   uri: '#drop-off-hubs',
  //   uris: ['https://onelink.to/jzcdu2', '#drop-off-hubs']
  // },
  // {
  //   image: FreeShippingCampaign,
  //   mobile: FreeShippingCampaignMobile,
  //   uri: 'https://blog.topship.africa/ship-for-free-in-2025-with-topship/'
  // },
  {
    image: SlideSeaFreight,
    mobile: SlideSeaFreightMobile,
    uri: 'https://ship.topship.africa'
  }
];

const isMobile = window.innerWidth <= 768;

export default function HomeCarousel() {
  return (
    <div className='banner-container'>
      <Slider dots speed={500} slidesToShow={1} slidesToScroll={1}>
        <div>
          <div
            className='content-wrap'
            style={{
              display: 'flex'
            }}
          >
            <div
              style={{
                position: 'relative'
              }}
              className='text-content'
            >
               
              <h2>
                Ship to <br />{' '}
                <span className='span-1'>
                  100+ countries <br /> from
                </span>
                <span className='span-2'> ₦600</span>{' '}
                <small
                  style={{
                    color: '#FEBC11',
                    fontWeight: 500
                  }}
                >
                  per kg
                </small>
              </h2>
              <img
                className='countries-web'
                src={OptionsIcon}
                alt='all-countries'
              />
              <img
                className='countries-mobile'
                src={MobileOptionsIcon}
                alt='all-countries'
              />
              <div className='cta-items'>
                <div className='cta-item web-hidden'>
                  <a href='#contact-form'>
                    <div
                      style={{
                        width: '150px'
                      }}
                      className='new-home-page__link-button bttn-sm bttn-yellow'
                    >
                      Contact Sales
                    </div>
                  </a>
                </div>
                <div className='cta-item mobile-hidden'>
                  <a href='https://ship.topship.africa/signup' target='_blank'>
                    <div className='new-home-page__link-button bttn-lg bttn-blue'>
                      Get Started
                    </div>
                  </a>
                </div>
                <div className='cta-item web-hidden'>
                  <a href='https://onelink.to/jzcdu2' target='_blank'>
                    <div
                      style={{
                        width: '150px'
                      }}
                      className='new-home-page__link-button bttn-sm bttn-blue'
                    >
                      Download App
                    </div>
                  </a>
                </div>
              </div>
              <div className='info-container'>
                <p className='info-text'>Don't want to wait for pick-up? </p>
                <a className='info-link' href='#drop-off-centers'>
                  See our drop-off centers
                </a>
              </div>
            </div>
            <div className='images-content'>
              <img className='qrcode' src={QrCode} alt='' />
            </div>
          </div>
        </div>
        {imageList.map((slide, index) => (
          <a href={slide.uri} target='_blank'>
            <div key={index} className='banner-item'>
              <img
                // onClick={event => {
                //   const percentageY = Math.floor(
                //     (event.nativeEvent.offsetY /
                //       (event.target as any).clientHeight) *
                //       100
                //   );

                //   const percentageX = Math.floor(
                //     (event.nativeEvent.offsetX /
                //       (event.target as any).clientWidth) *
                //       100
                //   );

                //   if (isMobile) {
                //     if (index === 0) {
                //       if (
                //         percentageY >= 60 &&
                //         percentageY < 75 &&
                //         percentageX >= 5 &&
                //         percentageX < 60
                //       ) {
                //         return window.open(slide.uris?.[0]);
                //       }

                //       if (
                //         percentageY >= 85 &&
                //         percentageY < 90 &&
                //         percentageX >= 5 &&
                //         percentageX < 50
                //       ) {
                //         return window.location.replace(
                //           `${window.location.origin}/${slide.uris?.[1]}`
                //         );
                //       }
                //     } else {
                //       if (
                //         percentageY >= 60 &&
                //         percentageY < 80 &&
                //         percentageX >= 5 &&
                //         percentageX < 50
                //       ) {
                //         return window.open(slide.uri);
                //       }
                //     }
                //   } else {
                //     if (index === 0) {
                //       if (
                //         percentageY >= 75 &&
                //         percentageY < 85 &&
                //         percentageX >= 25 &&
                //         percentageX < 50
                //       ) {
                //         return window.location.replace(
                //           `${window.location.origin}/${slide.uri}`
                //         );
                //       }
                //     } else {
                //       if (
                //         percentageY >= 65 &&
                //         percentageY < 85 &&
                //         percentageX >= 5 &&
                //         percentageX < 20
                //       ) {
                //         return window.open(slide.uri);
                //       }
                //     }
                //   }
                // }}
                src={isMobile ? slide.mobile : slide.image}
                alt='ad banner'
              />
            </div>
          </a>
        ))}
      </Slider>
    </div>
  );
}
