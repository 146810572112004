import { useEffect, useMemo, useState } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  TextField,
  InputAdornment
} from '@mui/material';
import { useStyles } from './new-home-page/constants';
import { useFormik } from 'formik';
import * as yup from 'yup';
import debounce from 'lodash.debounce';

import {
  useGetCountriesLazyQuery,
  useGetCitiesQuery,
  useGetShipmentRateQuery
} from '../operations/queries';
import Flag from '../assets/svgs/FlagBanner.svg';
import Globe from '../assets/svgs/GlobeSimple.svg';
import BusyOverlay from './busy-overlay';
import VolumetricWeight from './volumetric-weight-switch';
import trackMetaEvent from '../utilities/metaEventTracking';
import ReactGA from 'react-ga';

const GetQuotesForm = ({ shippingInfo }) => {
  const classes = useStyles();
  const [senderCities, setSenderCities] = useState([]);
  const [receiverCities, setRecipientCities] = useState([]);

  const defaultDetail = useMemo(
    () => ({
      country: 'Nigeria',
      countryCode: 'NG'
    }),
    []
  );

  const [getCountries, countries] = useGetCountriesLazyQuery();
  const countriesValue = countries?.data?.getCountries || [];
  function calculateTotalWeight(values) {
    let totalWeightValue = 0;

    if (!!values.isVolumetric) {
      const volumetricWeight = Number(
        ((values.width * values.height * values.length) / 5000).toFixed(2)
      );
      totalWeightValue += volumetricWeight;
    } else {
      totalWeightValue += values.weight;
    }

    return totalWeightValue;
  }
  const { errors, touched, values, handleSubmit, handleChange, setFieldValue } =
    useFormik({
      initialValues: {
        isVolumetric: false,
        weight: '',
        width: '',
        length: '',
        height: '',
        senderDetail: {
          city: shippingInfo?.sender?.city || '',
          country: shippingInfo?.sender?.country || defaultDetail.country,
          countryCode:
            shippingInfo?.sender?.countryCode || defaultDetail.countryCode,
          postalCode: ''
        },
        receiverDetail: {
          city: shippingInfo?.receiver?.city || '',
          country: shippingInfo?.receiver?.country || '',
          countryCode: shippingInfo?.receiver?.countryCode || '',
          postalCode: ''
        }
      },

      validationSchema: yup.object().shape({
        isVolumetric: yup.bool(),
        weight: yup.number().when(['isVolumetric'], {
          is: isVolumetric => isVolumetric === false,
          then: yup
            .number()
            .min(0.09999, 'Please enter a weight greater than zero (0)')
            .required('Please enter the weight of your item(s)'),
          otherwise: yup.number().nullable().notRequired()
        }),
        width: yup.number().when(['isVolumetric'], {
          is: isVolumetric => isVolumetric === true,

          then: yup
            .number()
            .min(0.09999, 'Please enter a width greater than zero (0)')
            .required('Please enter the width of your box'),
          otherwise: yup.number().nullable().notRequired()
        }),
        height: yup.number().when(['isVolumetric'], {
          is: isVolumetric => isVolumetric === true,

          then: yup
            .number()
            .min(0.09999, 'Please enter a height greater than zero (0)')
            .required('Please enter the height of your box'),
          otherwise: yup.number().nullable().notRequired()
        }),
        length: yup.number().when(['isVolumetric'], {
          is: isVolumetric => isVolumetric === true,

          then: yup
            .number()
            .min(0.09999, 'Please enter a length greater than zero (0)')
            .required('Please enter the length of your box'),
          otherwise: yup.number().nullable().notRequired()
        }),
        senderDetail: yup.object().shape({
          country: yup.string().required(`Please enter pickup country`),
          city: yup.string().required(`Please enter pickup city`)
        }),
        receiverDetail: yup.object().shape({
          country: yup.string().required(`Please enter destination country`),
          city: yup.string().required(`Please enter destination city`)
        })
      }),

      onSubmit: () => {
        const totalWeight = calculateTotalWeight(values);

        const requestRatesData = {
          senderDetails: {
            cityName: values.senderDetail.city,
            countryCode: values.senderDetail.countryCode,
            postalCode: values.senderDetail.postalCode
          },
          receiverDetails: {
            cityName: values.receiverDetail.city,
            countryCode: values.receiverDetail.countryCode,
            postalCode: values.receiverDetail.postalCode
          },
          totalWeight: totalWeight
        };

        getShipmentRates(requestRatesData);
      }
    });

  const senderCountryCode = values?.senderDetail?.countryCode?.toLowerCase();

  const receiverCountryCode =
    values?.receiverDetail?.countryCode?.toLowerCase();

  const sender = `${values?.senderDetail?.countryCode},${values?.senderDetail?.city}`;
  const receiver = `${values?.receiverDetail?.countryCode},${values?.receiverDetail?.city}`;
  let totalWeight = values.weight;
  if (values.isVolumetric) {
    totalWeight = Number(
      ((values.width * values.height * values.length) / 5000).toFixed(2)
    );
  }

  const params = new URLSearchParams({
    senderCountryCode,
    receiverCountryCode,
    sender,
    receiver,
    senderPostalCode: values.senderDetail.postalCode,
    receiverPostalCode: values.receiverDetail.postalCode,
    weight: String(totalWeight)
  }).toString();

  const quoteSummaryURL = `${'https://ship.topship.africa'}/viewquote?${params}`;

  const [getShipmentRates, { loading }] = useGetShipmentRateQuery(data => {
    if (data) {
      trackMetaEvent('GetQuote', {
        status: 'success'
      });
      ReactGA.event({
        category: 'GetQuote',
        status: 'success'
      });
      window.location.href = quoteSummaryURL;
    }
  });

  const [getSenderCities, senderCitiesResult] = useGetCitiesQuery(data =>
    setSenderCities(data)
  );

  const [getReceiverCities, receiverCitiesResult] = useGetCitiesQuery(data =>
    setRecipientCities(data)
  );

  useEffect(
    () => {
      getCountries();
      getSenderCities({
        countryCode:
          shippingInfo?.sender?.countryCode || defaultDetail.countryCode,
        cityName: shippingInfo?.sender?.city || 'a'
      });
      if (shippingInfo?.receiver?.countryCode) {
        getReceiverCities({
          countryCode: shippingInfo?.receiver?.countryCode,
          cityName: shippingInfo?.receiver?.city || 'a'
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const loadingState =
    loading || senderCitiesResult.loading || receiverCitiesResult.loading;

  return (
    <>
      <section className='form'>
        <div className='get-quotes-form'>
          <h2>Get Pricing</h2>
          <form onSubmit={handleSubmit} autoComplete='off'>
            <div className='quote-form-item quote-pickup'>
              <label htmlFor='pickup' className='quotes-label'>
                Pickup
              </label>
              <div className='sender-details'>
                <div className='column'>
                  <Autocomplete
                    id={`senderDetail.country`}
                    classes={{
                      input: classes.autoComplete,
                      option: classes.option
                    }}
                    options={countriesValue}
                    getOptionLabel={option => option.name}
                    isOptionEqualToValue={(option, value) =>
                      option?.name === value?.name
                    }
                    noOptionsText={
                      countries.loading
                        ? 'Countries loading...'
                        : 'Country not found'
                    }
                    onChange={(e, country) => {
                      if (country?.code && country?.name) {
                        getSenderCities({
                          countryCode: country?.code,
                          cityName: 'a'
                        });
                        setFieldValue(
                          'senderDetail',
                          {
                            ...values.senderDetail,
                            countryCode: country?.code,
                            country: country?.name
                          },
                          true
                        );
                      }
                    }}
                    value={
                      countriesValue?.find(
                        country => country?.name === values.senderDetail.country
                      ) || { name: '' }
                    }
                    renderOption={(props, option, country) => (
                      <Box
                        {...props}
                        key={props.id}
                        component='li'
                        sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                      >
                        {country && (
                          <img
                            loading='lazy'
                            width='30'
                            height='20'
                            src={`https://flagcdn.com/w20/${option.code?.toLocaleLowerCase()}.png`}
                            srcSet={`https://flagcdn.com/w20/${option.code?.toLocaleLowerCase()}.png 2x`}
                            alt=''
                          />
                        )}
                        {option.name} ({option.code})
                      </Box>
                    )}
                    renderInput={params => (
                      <TextField
                        {...params}
                        sx={{
                          '& legend': { display: 'none' },
                          '& fieldset': { top: 0 }
                        }}
                        autoComplete='off'
                        inputProps={{
                          ...params.inputProps
                        }}
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <>
                              <InputAdornment position='start'>
                                <img
                                  loading='lazy'
                                  width='30'
                                  height='20'
                                  src={`https://flagcdn.com/w20/${values.senderDetail.countryCode?.toLocaleLowerCase()}.png`}
                                  srcSet={`https://flagcdn.com/w20/${values.senderDetail.countryCode?.toLocaleLowerCase()}.png 2x`}
                                  alt=''
                                />
                              </InputAdornment>
                              {params.InputProps.startAdornment}
                            </>
                          ),
                          style: { fontSize: 14 }
                        }}
                        InputLabelProps={{ style: { fontSize: 14 } }}
                        value={values.senderDetail.country}
                        placeholder='Select country'
                        fullWidth
                      />
                    )}
                  />
                  {touched.senderDetail?.country &&
                    errors.senderDetail?.country && (
                      <p className='validation-error'>
                        {errors.senderDetail?.country}
                      </p>
                    )}
                </div>

                <div className='column'>
                  <Autocomplete
                    classes={{
                      input: classes.autoComplete,
                      option: classes.option
                    }}
                    id={`senderDetail.city`}
                    autoComplete={false}
                    options={senderCities}
                    getOptionLabel={option => option.city}
                    isOptionEqualToValue={(option, value) =>
                      option?.city === value?.city
                    }
                    renderOption={(props, option) => (
                      <Box
                        {...props}
                        key={props.id}
                        component='li'
                        sx={{
                          textTransform: 'capitalize'
                        }}
                      >
                        <div
                          style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                          }}
                        >
                          <span>{option?.city?.toLowerCase()}</span>
                          {option?.postalCode && (
                            <span style={{ fontSize: '12px' }}>
                              {option?.postalCode?.toLowerCase()}
                            </span>
                          )}
                        </div>
                      </Box>
                    )}
                    noOptionsText={
                      !values.senderDetail.countryCode
                        ? 'Please select a country for Pick Up'
                        : senderCitiesResult.loading
                        ? 'Cities loading...'
                        : 'City not found'
                    }
                    onChange={(event, value) => {
                      if (value?.city) {
                        let postalCode = null;

                        if (
                          values.senderDetail.countryCode !==
                          defaultDetail.countryCode
                        ) {
                          postalCode = value?.postalCode;
                        }

                        setFieldValue(
                          'senderDetail',
                          {
                            ...values.senderDetail,
                            city: value?.city,
                            postalCode
                          },
                          true
                        );
                      }
                    }}
                    value={senderCities?.find(
                      city => city.city === values.senderDetail.city
                    )}
                    renderInput={params => (
                      <TextField
                        {...params}
                        sx={{
                          '& legend': { display: 'none' },
                          '& fieldset': { top: 0 }
                        }}
                        autoComplete='off'
                        inputProps={{
                          ...params.inputProps
                        }}
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <>
                              <InputAdornment position='start'>
                                <img src={Flag} alt='flag' />
                              </InputAdornment>
                              {params.InputProps.startAdornment}
                            </>
                          ),
                          style: { fontSize: 14 }
                        }}
                        InputLabelProps={{ style: { fontSize: 14 } }}
                        value={values.senderDetail.city}
                        onChange={debounce(
                          event =>
                            getSenderCities({
                              countryCode: values.senderDetail.countryCode,
                              cityName: event?.target?.value
                            }),
                          100
                        )}
                        placeholder='Select city'
                        fullWidth
                      />
                    )}
                  />
                  {touched.senderDetail?.city && errors.senderDetail?.city && (
                    <p className='validation-error'>
                      {errors.senderDetail?.city}
                    </p>
                  )}
                </div>
                <div className='column'>
                  <TextField
                    className={classes.input}
                    id='senderDetail.postalCode'
                    type='text'
                    step='1.00'
                    variant='outlined'
                    sx={{
                      '& legend': { display: 'none' },
                      '& fieldset': { top: 0 }
                    }}
                    InputProps={{
                      InputProps: { type: 'number', min: 0, max: 10 },
                      style: {
                        fontSize: 14,
                        height: '48px'
                      }
                    }}
                    InputLabelProps={{
                      style: { fontSize: 12 }
                    }}
                    onChange={handleChange}
                    value={values?.senderDetail?.postalCode}
                    placeholder='Enter postal code (optional)'
                    fullWidth
                  />
                  {touched?.senderDetail?.postalCode &&
                    errors?.senderDetail?.postalCode && (
                      <p className='validation-error'>
                        {errors?.senderDetail?.postalCode}
                      </p>
                    )}
                </div>
              </div>
            </div>
            <div className='quote-form-item quote-destination'>
              <label htmlFor='destination' className='quotes-label'>
                Destination
              </label>
              <div className='receiver-details'>
                <div className='column'>
                  <Autocomplete
                    id={`receiverDetail.country`}
                    classes={{
                      input: classes.autoComplete,
                      option: classes.option
                    }}
                    options={countriesValue}
                    getOptionLabel={option => option.name || ''}
                    isOptionEqualToValue={(option, value) =>
                      option?.name === value?.name
                    }
                    noOptionsText={
                      countries.loading
                        ? 'Countries loading...'
                        : 'Country not found'
                    }
                    onChange={(e, country) => {
                      if (country.code && country.name) {
                        getReceiverCities({
                          countryCode: country.code,
                          cityName: 'a'
                        });
                        setFieldValue(
                          'receiverDetail',
                          {
                            ...values.receiverDetail,
                            countryCode: country?.code,
                            country: country?.name
                          },
                          true
                        );
                      }
                    }}
                    value={
                      countriesValue.find(
                        country =>
                          country?.name === values.receiverDetail.country
                      ) || { name: '' }
                    }
                    renderOption={(props, option) => (
                      <Box
                        {...props}
                        key={props.id}
                        component='li'
                        sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                      >
                        <img
                          loading='lazy'
                          width='30'
                          height='20'
                          src={
                            option.code
                              ? `https://flagcdn.com/w20/${option.code?.toLocaleLowerCase()}.png`
                              : Globe
                          }
                          srcSet={
                            option.code
                              ? `https://flagcdn.com/w20/${option.code?.toLocaleLowerCase()}.png 2x`
                              : Globe
                          }
                          alt=''
                        />
                        {option.name} ({option.code})
                      </Box>
                    )}
                    renderInput={params => (
                      <TextField
                        {...params}
                        sx={{
                          '& legend': { display: 'none' },
                          '& fieldset': { top: 0 }
                        }}
                        inputProps={{
                          ...params.inputProps
                        }}
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <>
                              <InputAdornment position='start'>
                                <img
                                  loading='lazy'
                                  width='30'
                                  height='20'
                                  src={
                                    values.receiverDetail.countryCode
                                      ? `https://flagcdn.com/w20/${values.receiverDetail.countryCode?.toLocaleLowerCase()}.png`
                                      : Globe
                                  }
                                  srcSet={
                                    values.receiverDetail.countryCode
                                      ? `https://flagcdn.com/w20/${values.receiverDetail.countryCode?.toLocaleLowerCase()}.png 2x`
                                      : Globe
                                  }
                                  alt=''
                                />
                              </InputAdornment>
                              {params.InputProps.startAdornment}
                            </>
                          ),
                          style: { fontSize: 14 }
                        }}
                        InputLabelProps={{ style: { fontSize: 14 } }}
                        value={values.receiverDetail.country}
                        placeholder='Select country'
                        fullWidth
                      />
                    )}
                  />
                  {touched.receiverDetail?.country &&
                    errors.receiverDetail?.country && (
                      <p className='validation-error'>
                        {errors.receiverDetail?.country}
                      </p>
                    )}
                </div>
                <div className='column'>
                  <Autocomplete
                    id={`receiverDetail.city`}
                    classes={{
                      input: classes.autoComplete,
                      option: classes.option
                    }}
                    options={receiverCities}
                    getOptionLabel={option => option.city}
                    isOptionEqualToValue={(option, value) =>
                      option?.city === value?.city
                    }
                    renderOption={(props, option) => (
                      <Box
                        {...props}
                        key={props.id}
                        component='li'
                        sx={{
                          textTransform: 'capitalize'
                        }}
                      >
                        <div
                          style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                          }}
                        >
                          <span>{option?.city?.toLowerCase()}</span>
                          {option?.postalCode && (
                            <span style={{ fontSize: '12px' }}>
                              {option?.postalCode?.toLowerCase()}
                            </span>
                          )}
                        </div>
                      </Box>
                    )}
                    noOptionsText={
                      !values.receiverDetail.countryCode
                        ? 'Please select a country for Destination'
                        : receiverCitiesResult.loading
                        ? 'Cities loading...'
                        : 'City not found'
                    }
                    onChange={(event, value) => {
                      if (value?.city) {
                        let postalCode = value?.postalCode;

                        if (
                          values.receiverDetail.countryCode ===
                          defaultDetail.countryCode
                        ) {
                          postalCode = null;
                        }

                        setFieldValue(
                          'receiverDetail',
                          {
                            ...values.receiverDetail,
                            city: value?.city,
                            postalCode
                          },
                          true
                        );
                      }
                    }}
                    value={receiverCities?.find(
                      city => city.city === values.receiverDetail.city
                    )}
                    renderInput={params => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps
                        }}
                        sx={{
                          '& legend': { display: 'none' },
                          '& fieldset': { top: 0 }
                        }}
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <>
                              <InputAdornment position='start'>
                                <img src={Flag} alt='flag' />
                              </InputAdornment>
                              {params.InputProps.startAdornment}
                            </>
                          ),
                          style: { fontSize: 14 }
                        }}
                        InputLabelProps={{ style: { fontSize: 14 } }}
                        value={values.receiverDetail.city}
                        onChange={debounce(
                          event =>
                            getReceiverCities({
                              countryCode: values.receiverDetail.countryCode,
                              cityName: event?.target?.value
                            }),
                          100
                        )}
                        placeholder='Select city'
                        fullWidth
                      />
                    )}
                  />
                  {touched.receiverDetail?.city &&
                    errors.receiverDetail?.city && (
                      <p className='validation-error'>
                        {errors.receiverDetail?.city}
                      </p>
                    )}
                </div>
                <div className='column'>
                  <TextField
                    className={classes.input}
                    id='receiverDetail.postalCode'
                    type='text'
                    step='1.00'
                    variant='outlined'
                    sx={{
                      '& legend': { display: 'none' },
                      '& fieldset': { top: 0 }
                    }}
                    InputProps={{
                      InputProps: { type: 'number', min: 0, max: 10 },
                      style: {
                        fontSize: 14,
                        height: '48px'
                      }
                    }}
                    InputLabelProps={{
                      style: { fontSize: 12 }
                    }}
                    onChange={handleChange}
                    value={values?.receiverDetail?.postalCode}
                    placeholder='Enter postal code (optional)'
                    fullWidth
                  />
                  {touched?.receiverDetail?.postalCode &&
                    errors?.receiverDetail?.postalCode && (
                      <p className='validation-error'>
                        {errors?.receiverDetail?.postalCode}
                      </p>
                    )}
                </div>
              </div>
            </div>
            <div className='mb-3'>
              <VolumetricWeight
                checkBoxName={`isVolumetric`}
                isActive={values.isVolumetric}
                handleToggleSwitch={() =>
                  setFieldValue(`isVolumetric`, !values.isVolumetric)
                }
              />
            </div>

            {values.isVolumetric ? (
              <div>
                <div className='quote-form-item quote-weight'>
                  <label
                    htmlFor='width'
                    className='quotes-label'
                    style={{
                      marginBottom: '0'
                    }}
                  >
                    Width (CM)
                  </label>
                  <div className='quote-weight-details'>
                    <TextField
                      className={classes.input}
                      id='width'
                      type='number'
                      step='1.00'
                      variant='outlined'
                      sx={{
                        '& legend': { display: 'none' },
                        '& fieldset': { top: 0 }
                      }}
                      InputProps={{
                        InputProps: { type: 'number', min: 0, max: 10 },
                        style: {
                          fontSize: 16,
                          height: '48px'
                        }
                      }}
                      InputLabelProps={{
                        style: { fontSize: 12 }
                      }}
                      onChange={handleChange}
                      value={values.width}
                      placeholder='Width (CM)'
                      fullWidth
                    />
                    {touched.width && errors.width && (
                      <p className='validation-error'>{errors.width}</p>
                    )}
                  </div>
                </div>
                <div className='quote-form-item quote-weight'>
                  <label
                    htmlFor='width'
                    className='quotes-label'
                    style={{
                      marginBottom: '0'
                    }}
                  >
                    Height (CM)
                  </label>
                  <div className='quote-weight-details'>
                    <TextField
                      className={classes.input}
                      id='height'
                      type='number'
                      step='1.00'
                      variant='outlined'
                      sx={{
                        '& legend': { display: 'none' },
                        '& fieldset': { top: 0 }
                      }}
                      InputProps={{
                        InputProps: { type: 'number', min: 0, max: 10 },
                        style: {
                          fontSize: 16,
                          height: '48px'
                        }
                      }}
                      InputLabelProps={{
                        style: { fontSize: 12 }
                      }}
                      onChange={handleChange}
                      value={values.height}
                      placeholder='Height (CM)'
                      fullWidth
                    />
                    {touched.height && errors.height && (
                      <p className='validation-error'>{errors.height}</p>
                    )}
                  </div>
                </div>
                <div className='quote-form-item quote-weight'>
                  <label
                    htmlFor='width'
                    className='quotes-label'
                    style={{
                      marginBottom: '0'
                    }}
                  >
                    Length (CM)
                  </label>
                  <div className='quote-weight-details'>
                    <TextField
                      className={classes.input}
                      id='length'
                      type='number'
                      step='1.00'
                      variant='outlined'
                      sx={{
                        '& legend': { display: 'none' },
                        '& fieldset': { top: 0 }
                      }}
                      InputProps={{
                        InputProps: { type: 'number', min: 0, max: 10 },
                        style: {
                          fontSize: 16,
                          height: '48px'
                        }
                      }}
                      InputLabelProps={{
                        style: { fontSize: 12 }
                      }}
                      onChange={handleChange}
                      value={values.length}
                      placeholder='Length (CM)'
                      fullWidth
                    />
                    {touched.length && errors.length && (
                      <p className='validation-error'>{errors.length}</p>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div className='quote-form-item quote-weight'>
                <label
                  htmlFor='weight'
                  className='quotes-label'
                  style={{
                    marginBottom: '0'
                  }}
                >
                  Item Weight (kg)
                </label>
                <div className='quote-weight-details'>
                  <TextField
                    className={classes.input}
                    id='weight'
                    type='number'
                    step='1.00'
                    variant='outlined'
                    sx={{
                      '& legend': { display: 'none' },
                      '& fieldset': { top: 0 }
                    }}
                    InputProps={{
                      InputProps: { type: 'number', min: 0, max: 10 },
                      style: {
                        fontSize: 16,
                        height: '48px'
                      }
                    }}
                    InputLabelProps={{
                      style: { fontSize: 12 }
                    }}
                    onChange={handleChange}
                    value={values.weight}
                    placeholder='Item Weight (kg)'
                    fullWidth
                  />
                  {touched.weight && errors.weight && (
                    <p className='validation-error'>{errors.weight}</p>
                  )}
                </div>
              </div>
            )}
            <Button type='submit' className='get-quote-submit-button'>
              Request a Quote
            </Button>
          </form>
        </div>
      </section>
      <BusyOverlay loading={loadingState} />
    </>
  );
};

export default GetQuotesForm;
